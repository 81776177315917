<template>
  <div class="details-box">
    <div class="shop-box">
      <div class="img">
        <img src="" alt="">
      </div>
      <div class="describe">
        <div class="title">悦淘旅游大使上线 巨额奖金寻找热爱旅行的你</div>
        <div class="num">2能量</div>
      </div>
    </div>
    <div class="time-box">
      <div class="title">出行日期</div>
      <div class="item">
        <div>12-28</div>
      </div>
    </div>
    <div class="product-box">
      <div class="title">产品详情</div>
      <div class="img">
        <img src="" alt="">
      </div>
    </div>

    <div class="page-root">
      <div class="left">余额：50能量</div>
      <div class="right">立即兑换</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {

    }
  },
  mounted() {

  },
  methods: {

  }
}
</script>
<style scoped>
.shop-box{
    background: #fff;
}
.shop-box .img{
    width: 100%;
    height: 350px;
    background: lightcoral;
}
.shop-box .img img{
    width: 100%;
    height: 100%;
}
.shop-box .describe{
    text-align: left;
    font-size: 14px;
    color: #333;
    padding:10px;
    font-weight: bold;
}
.shop-box .describe .num{
    padding-top: 10px;
    color: #FA3742;
}
.time-box{
    background: #fff;
    margin-top: 15px;
    padding: 10px;
    color: #333;
    font-size: 14px;
    text-align: left;
    font-weight: bold;
}
.time-box .item{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 5px;
}
.time-box .item div{
    background: #FA3742;
    padding: 10px 15px;
    margin-right: 10px;
    color: #fff;
    border-radius: 5px;
}
.product-box{
    margin-top: 15px;
    background: #fff;
    font-size: 14px;
    color: #333;
    padding: 10px 5px;
    font-weight: bold;
}
.product-box .img img{
    margin-top: 13px;
    width: 100%;
    height: 100%;
}
.page-root{
    background: #fff;
    padding: 20px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: bold;
    color: #FA3742;
    position: fixed;
    bottom: 0;
    width: 100%;
}
.page-root .right{
    padding: 7px 25px;
    color: #fff;
    border-radius: 5px;
    background: #FA3742;
}
</style>
